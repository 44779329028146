<template>
    <div ref="rivet"></div>
</template>

<script setup>
import { ref, h, render, onUnmounted, watch, onMounted } from 'vue';
import selectChildComp from './selectChild.vue';

const props = defineProps({
    value: Boolean,
    options: Array
});
const rivet = ref(null);

const isVisible = ref(false);
let container = null;

const emit = defineEmits(['update:value', 'select']);
const handleClickOutside = (event) => {
    if (rivet.value.parentElement && !rivet.value.parentElement.contains(event.target)) {
        isVisible.value = false;
    }
};

watch(() => isVisible.value, (newVal) => {
    emit('update:value', newVal);
    if (newVal) {
        createSelectComp();
        nextTick(() => {
            document.addEventListener('click', handleClickOutside);
        });
        document.addEventListener('click', handleClickOutside);
    } else if (container) {
        render(null, container);
        document.body.removeChild(container);
        container = null;
        document.removeEventListener('click', handleClickOutside);
    }
}, {
    immediate: true
});
watch(() => props.value, (newVal) => {
    isVisible.value = newVal;
});
const getParentBottomLeft = () => {
    const parent = rivet.value.parentElement;
    if (parent) {
        const rect = parent.getBoundingClientRect();
        return {
            left: rect.left,
            bottom: rect.bottom
        };
    }
    return null;
};




const createSelectComp = () => {
    // 获取父节点的位置并设置容器的位置
    const parentPosition = getParentBottomLeft();
    if (parentPosition) {
        container = document.createElement('div');

        container.style.position = 'absolute';
        container.style.left = `${parentPosition.left}px`;
        container.style.top = `${parentPosition.bottom + 4}px`;
        const vnode = h(selectChildComp, {
            options: props.options,
            onSelect: (value, option) => {
                emit('select', value, option);
                isVisible.value = false;
            }
        });

        document.body.appendChild(container);
        render(vnode, container);
    }
};
onMounted(() => {
    isVisible.value = props.value
    document.removeEventListener('click', handleClickOutside);
});
onUnmounted(() => {
    if (container) {
        render(null, container);
        document.body.removeChild(container);
        container = null;
    }
});
</script>