<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
        <path
            d="M3.79497 1.65399C4.08472 1.07188 4.91519 1.07188 5.20495 1.65399L5.91649 3.08345L7.34596 3.795C7.92806 4.08475 7.92806 4.91522 7.34596 5.20498L5.91649 5.91652L5.20495 7.34599C4.91519 7.92809 4.08472 7.92809 3.79497 7.34599L3.08342 5.91652L1.65395 5.20498C1.07185 4.91522 1.07185 4.08475 1.65396 3.795L3.08342 3.08345L3.79497 1.65399ZM4.49996 2.76229L4.04012 3.68608C3.96376 3.83948 3.83945 3.96379 3.68605 4.04015L2.76226 4.49999L3.68605 4.95982C3.83945 5.03618 3.96376 5.16049 4.04012 5.31389L4.49996 6.23769L4.95979 5.31389C5.03615 5.16049 5.16046 5.03618 5.31386 4.95982L6.23766 4.49999L5.31386 4.04015C5.16046 3.96379 5.03615 3.83948 4.95979 3.68608L4.49996 2.76229Z"
            fill="black" fill-opacity="0.95" />
        <path
            d="M5.66997 9.90399C5.95972 9.32188 6.79019 9.32188 7.07995 9.90399L7.91612 11.5838L9.59596 12.42C10.1781 12.7097 10.1781 13.5402 9.59596 13.83L7.91612 14.6661L7.07995 16.346C6.79019 16.9281 5.95972 16.9281 5.66997 16.346L4.8338 14.6661L3.15395 13.83C2.57185 13.5402 2.57185 12.7098 3.15396 12.42L4.8338 11.5838L5.66997 9.90399ZM6.37496 11.0123L5.79049 12.1865C5.71413 12.3399 5.58983 12.4642 5.43643 12.5405L4.26226 13.125L5.43643 13.7095C5.58983 13.7858 5.71414 13.9101 5.79049 14.0635L6.37496 15.2377L6.95942 14.0635C7.03578 13.9101 7.16009 13.7858 7.31349 13.7095L8.48766 13.125L7.31349 12.5405C7.16009 12.4642 7.03578 12.3399 6.95942 12.1865L6.37496 11.0123Z"
            fill="black" fill-opacity="0.95" />
        <path
            d="M13.0887 3.94896C12.8059 3.34302 11.9442 3.34302 11.6614 3.94896L10.6393 6.13919L8.44903 7.16137C7.84308 7.44416 7.84308 8.3058 8.44903 8.58859L10.6393 9.61077L11.6614 11.801C11.9442 12.4069 12.8059 12.4069 13.0887 11.801L14.1108 9.61077L16.3011 8.58859C16.907 8.3058 16.907 7.44417 16.3011 7.16137L14.1108 6.13919L13.0887 3.94896ZM11.6068 6.72617L12.375 5.08005L13.1433 6.72617C13.2215 6.89378 13.3562 7.02851 13.5239 7.10673L15.17 7.87498L13.5239 8.64323C13.3562 8.72145 13.2215 8.85618 13.1433 9.02379L12.375 10.6699L11.6068 9.02379C11.5286 8.85618 11.3938 8.72145 11.2262 8.64323L9.58011 7.87498L11.2262 7.10673C11.3938 7.02851 11.5286 6.89378 11.6068 6.72617Z"
            fill="black" fill-opacity="0.95" />
    </svg>
</template>