<script setup>
import { Gradient } from './gl/index'
import SiriWave from 'siriwave'
import center from './center.vue';
import top from './top.vue'
import { theme } from 'ant-design-vue'
let gradient = null
let siriWaveObj = null

onMounted(() => {
    gradient = new Gradient()
    gradient.initGradient('#gradient-canvas')

    siriWaveObj = new SiriWave({
        container: document.getElementById("siri-container"),
        width: window.innerWidth,
        height: 200,
        style: 'ios9',
        curveDefinition: [
            { color: "13, 13, 13", supportLine: true },  // 支撑线(必需)
            { color: "70, 2, 213" },
            { color: "43, 141, 241" },
            { color: "66, 3, 210" },
        ],
        amplitude: 8,
        speed: 0.000001,  // 降低基础速度
        autostart: true,
        color: '#fff',
        cover: true,
        pixelDepth: 0.02,
        lerpSpeed: 0.01,
        ranges: {
            noOfCurves: [2, 5],
            amplitude: [2, 2.5],
            offset: [-0.6, 0.6],
            width: [2, 5],
        }
    });
})

onUnmounted(() => {
    gradient = null
    siriWaveObj = null
})
const fontFamily = "'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"


</script>

<template>
    <div class="gl-container">
        <div id="siri-container"></div>
        <canvas id="gradient-canvas" data-transition-in></canvas>
    </div>
    <div>
        <a-config-provider :theme="{
            algorithm: theme.darkAlgorithm,
            token: {
                fontFamily: fontFamily,
                colorBgLayout: '#23283D'

            },
        }">
            <center></center>
            <top></top>
        </a-config-provider>
    </div>
</template>

<style scoped>
.gl-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    overflow: hidden;
}

#gradient-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    --gradient-color-1: #111113;
    --gradient-color-2: #111113;
    --gradient-color-3: #111113;
    --gradient-color-4: #5656F6;
}

#siri-container {
    margin: 0 auto;
    position: fixed;
    width: 800px;
    left: 50%;
    height: 340px;
    bottom: 0;
    transform: translate(-50%, 50%);
    z-index: 1;
    filter: blur(30px);
}
</style>