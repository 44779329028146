<script setup>
import mogicImage from '../icons/mogicImage.vue';
import mogicClose from '../icons/mogicClose.vue';
import Icon from '@ant-design/icons-vue';
import {
    LoadingOutlined,
    // CloseOutlined
} from '@ant-design/icons-vue';
const props = defineProps({
    src: {
        type: String,
        default: ''
    },
    status: {
        type: String,
        default: 'ready'
    }
})
const emit = defineEmits(['delete'])
function deleteFun() {
    if (props.src) {
        emit('delete', props.src);
    }
}
const resourceLoad = ref(false)
watch(() => props.src, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        resourceLoad.value = false
    }
})
function loaded() {
    resourceLoad.value = true
}
// ready =>upload => uploaded  => error
// const status = ref('ready')
</script>
<template>
    <div class="w-[94px] h-[94px] rounded-[10px] backdrop-blur-xl justify-center items-center gap-2.5 mogic-image-comp">
        <img class="absolute max-h-full rounded-[6px]" :src="props.src" @load="loaded" v-show="resourceLoad"
            crossorigin="anonymous">
        <div class="w-5 h-5 relative" v-if="!resourceLoad">
            <loading-outlined v-if="status === 'uploaded' || status === 'upload'"
                style="color: rgba(255,255,255, 0.8); font-size: 20px;" />
            <mogicImage v-else-if="status === 'ready'"></mogicImage>
        </div>
        <div v-else class="h-[26px] p-[3px] bg-black/60 rounded-md justify-center items-center relative hidden icon">
            <div class="w-5 h-5 relative">
                <Icon @click.stop="deleteFun" style="vertical-align: baseline;">
                    <template #component>
                        <mogicClose width="20" height="20" />
                    </template>
                </Icon>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mogic-image-comp {
    background: rgba(255, 255, 255, 0.05);
}

.mogic-image-comp:hover {
    background: rgba(255, 255, 255, 0.07);
}

.mogic-image-comp:hover .icon {
    display: inline-flex;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
}

.mogic-image-comp .icon svg {
    vertical-align: baseline;
}
</style>