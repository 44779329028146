<script setup>
import { useConversationStore } from '@/stores/conversation.js'
import mogicDel from '../icons/mogicDel.vue';
import Icon from '@ant-design/icons-vue';
import iconButtonComp from '../UI/iconButtonComp.vue';
import tooltipComp from '../UI/tooltipComp.vue';
// import selectComp from '../UI/selectComp.vue';
import selectButtonComp from '../UI/selectButtonComp.vue';
import mogicProject from '../icons/mogicProject.vue';
import imagePlaceholder from './imagePlaceholder.vue';
// import axios from 'axios';
import { protocol, status } from '@/hook/protocolHook';
import buttonComp from '../UI/buttonComp.vue';
import message from '@/components/UI/message';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as fabric from 'fabric';
const { conversation } = useConversationStore()
// const cards = ref([
//     { id: 1, text: 'Please extract the background elements from this image', header: 'Extract Background' },
//     { id: 2, text: 'Please extract the product from the image', header: 'Extract Product' },
// ]);
const cards = ref([])
const selectedCards = ref([])
const json = reactive([])


const fabricId = ref(null)
// const myCanvas = new Canvas(fabricId)
/**
 * @type {fabric.Canvas|null} myCanvas - 声明一个变量 myCanvas，表示 fabric.Canvas 的实例，初始值为 null。
 */
let myCanvas = null

watch(protocol, (newVal) => {
    // debugger
    if (protocol && protocol.length > 0) {

        initCanvas(protocol[0].width, protocol[0].height)
        json.splice(0, 1, protocol[0])
        selectedCards.value.splice(0, selectedCards.value.length)
        if (protocol[0]?.child) {
            cards.value = protocol[0].child.map((item) => {
                return {
                    ...item
                }
            });
            if (protocol[0].child.length > 0) {
                selectFunc(protocol[0].child[0])
            }
        }
    }
}, { immediate: false })

async function downloadImg(key) {
    if (key === 0) {
        if (selectedCards.value.length > 1) {
            message('warning', 'please select only one image')
            return
        }
        if (selectedCards.value.length === 0) {
            message('warning', 'please select at least one image')
            return
        }
        const selectedCard = cards.value.find(card => selectedCards.value.includes(card.id));
        if (selectedCard) {
            const link = document.createElement('a');
            link.href = selectedCard.path;
            link.download = selectedCard.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    } else {
        if (cards.value.length === 0) {
            message('warning', 'at least one image');
            return;
        }

        const zip = new JSZip();
        const imgFolder = zip.folder('images');

        for (const card of cards.value) {
            const response = await fetch(card.path);
            const blob = await response.blob();
            imgFolder.file(card.name + '.png', blob);
        }
        zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, 'images.zip');
        });
    }
}


function selectFunc(layer) {
    // 如需多选，取消注释下面的代码
    // if (selectedCards.value.includes(id)) {
    //     selectedCards.splice(selectedCards.indexOf(card.id), 1)
    // } else {
    //     selectedCards.value.push(id)
    // }

    //单选
    // debugger
    if (selectedCards.value.length > 0) {
        const tempId = selectedCards.value.pop()
        // events.emit('layer-change', { id: tempId }, ['visible'], false)
        myCanvas.clear()
        if (tempId === layer.id) {
            return
        }
    }
    selectedCards.value.push(layer.id)
    // debugger
    // myCanvas.add(new fabric.Image(layer.path, {
    //     left: layer.boundBox[0],
    //     top: layer.boundBox[1],
    //     width: layer.boundBox[2],
    //     height: layer.boundBox[2],
    // }))

    fabric.FabricImage.fromURL(layer.path, { crossOrigin: 'anonymous' }).then((img) => {
        // debugger
        img.set({
            left: layer.boundBox[0],
            top: layer.boundBox[1],
            width: layer.boundBox[2],
            height: layer.boundBox[3],
            angle: 0,
            opacity: 1.0
        });
        myCanvas.add(img);
        // myCanvas.renderAll();
    })
    // myCanvas.add(new fabric.FabricImage(layer.path))
    // events.emit('layer-change', { id: id }, ['visible'], true)
}

const initCanvas = (width, height) => {
    const container = document.getElementById('fabric-canvas-container').parentElement;
    if (!myCanvas) {
        try {
            myCanvas = new fabric.StaticCanvas('fabricId')
        } catch (error) {
            console.error('canvas is not initialized')
            console.error(error)
        }
        return
    }
    myCanvas.clear();
    nextTick(() => {
        let targetWidth, targetHeight;
        const clientWidth = container.clientWidth * 60 / 100;
        const clientHeight = container.clientHeight - 44;
        const maxClientWidth = container.clientWidth - 280;
        if (width > height) {
            targetWidth = clientWidth;
            targetHeight = height * clientWidth / width;
            myCanvas.setZoom(clientWidth / width)
        } else if (width * clientHeight / height > maxClientWidth) {
            targetWidth = maxClientWidth;
            targetHeight = height * maxClientWidth / width;
            myCanvas.setZoom(maxClientWidth / width)
        } else {
            targetHeight = clientHeight;
            targetWidth = width * clientHeight / height;
            myCanvas.setZoom(clientHeight / height)
        }
        myCanvas.setDimensions({ width: targetWidth, height: targetHeight });
        myCanvas.renderAll();
        // myCanvas.setDimensions({ width: width || container.clientWidth, height: height || container.clientHeight });
        // myCanvas.renderAll();
        if (width) {
            // debugger
            // myCanvas.setZoom(container.clientWidth / width)
        }
    })
};
const handleBeforeUnload = (event) => {
    console.log('refresh');
    // event.preventDefault();
    // event.returnValue = ''; // 现代浏览器需要设置 returnValue 属性
};
onMounted(() => {
    // debugger
    // window.addEventListener('beforeunload', handleBeforeUnload);
    if (document.getElementById('fabricId')) {
        // myCanvas = new fabric.Canvas('fabricId')
        initCanvas()
    }
})

onBeforeUnmount(() => {
    // window.removeEventListener('beforeunload', handleBeforeUnload);
});

</script>
<template>
    <div class="container">
        <header class="header">
            <h1 class="title" style="visibility: hidden;">{{ '' && conversation.title }}</h1>
            <span>
                <tooltipComp title="No assets available for export" :disabled="cards.length === 0">
                    <selectButtonComp style="margin-right: 10px;vertical-align: middle;" :disabled="cards.length === 0"
                        :options="[{ value: 0, label: 'Export selected assets' },
                        { value: 1, label: 'Export all assets' }]" @select="downloadImg">
                        <template #icon>
                            <Icon>
                                <template #component>
                                    <mogicProject />
                                </template>
                            </Icon>
                        </template>
                        Export Assets
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                            style="display: inline-flex;margin-left: 4px;" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M11.2124 5.37814C11.0415 5.20729 10.7645 5.20729 10.5936 5.37814L7.37842 8.59336C7.16956 8.80221 6.82523 8.80221 6.61637 8.59336L3.40116 5.37814C3.2303 5.20729 2.95329 5.20729 2.78244 5.37814C2.61158 5.549 2.61158 5.826 2.78244 5.99686L5.99765 9.21208C6.54822 9.76264 7.44657 9.76264 7.99714 9.21208L11.2124 5.99686C11.3832 5.826 11.3832 5.549 11.2124 5.37814Z" />
                        </svg>
                    </selectButtonComp>
                </tooltipComp>
                <iconButtonComp style="vertical-align: middle;">
                    <mogicDel />
                </iconButtonComp>
            </span>
        </header>
        <div class="content">
            <!-- <canvas id="fabricId" v-if="true || json && json.length && status === 'ready'" /> -->
            <div v-show="json && json.length && status === 'ready'" id="fabric-canvas-container">
                <canvas id="fabricId" />
            </div>
            <imagePlaceholder v-show="!json || !json.length || status !== 'ready'" />
            <div
                class="sidebar absolute left-0 w-[140px] h-full overflow-y-auto flex flex-col justify-center items-center">
                <div class="space-y-4">
                    <div v-for="(card, i) in cards" :key="i" @click="selectFunc(card)"
                        class="h-[72px] w-[72px] p-1 bg-white/5 rounded-lg border-white"
                        :class="[selectedCards.includes(card.id) ? 'border-2' : 'border-0']">
                        <img :src="card.path" class="w-full h-full object-cover rounded-lg" crossorigin="anonymous" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #19191A;
    /* backdrop-blur/2xl */
    backdrop-filter: blur(calc(var(--blur-2xl, 40px) / 2));
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    border-bottom: var(--border-width-1, 1px) solid rgba(255, 255, 255, 0.08);
    background: rgba(25, 25, 26, 0.90);

    /* backdrop-blur/2xl */
    backdrop-filter: blur(calc(var(--blur-2xl, 40px) / 2));
}

.title {
    margin: 0;
    /* Display/3/Medium */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 20.8px */
}


.content {
    flex: 1;
    overflow: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#fabric-canvas-container {
    /* width: 60%; */
    /* height: calc(100% - 44px);
    overflow: auto; */
    background-color: #ffffff0d;
    background-image: url('/image/grid.png');
    background-repeat: repeat;
    background-size: auto;
}
</style>