import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StartPage from '@/components/mainpage/StartPage.vue';
import welcomePage from '@/views/welcome/index.vue';
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import axios from 'axios';
import { setCookie, getUserData } from '../hook/axiosUtils';

const router = createRouter({
  // history: createWebHashHistory(import.meta.env.BASE_URL),
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      // name: 'home',
      component: welcomePage
      // redirect: '/welcome'
    },
    {
      path: '/welcome',
      name: 'welcome',
      // component: StartPage
      component: welcomePage
    },
    {
      path: '/task/:id',
      name: 'chat',
      component: HomeView,
      props: true
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../views/test/test.vue')
      // redirect: '/about'
    },
    {
      path: '/testlogin',
      component: () => import('../views/test/login.vue')
      // redirect: '/about'
    },
  ]
})
router.beforeEach((to, from, next) => {
  // Perform some logic before each route change
  console.log(`Navigating to ${to.name} from ${from.name}`);
  // 检查cookie中是否存在token，存在则为已经登陆
  const tokenString = document.cookie.split('; ').find(row => row.startsWith('access_token='));
  const token = tokenString ? tokenString.split('=')[1] : null;
  if (token && token === localStorage.getItem('token')) {
    next()
  } else if(token) {
    // 认为是谷歌登录
    getUserData(token, next, () => {
      setCookie(next)
    })
  } else {
    // 游客模式
    setCookie(next)
  }
});
export default router
