<template>
    <svg width="1em" height="1em" viewBox="0 0 18 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1000004609">
            <g id="Union">
                <path
                    d="M13.5568 5.98833C13.8265 5.77291 13.8705 5.37964 13.6551 5.10993C13.4396 4.84022 13.0464 4.79621 12.7767 5.01164L10.1688 7.09458C9.53849 7.59611 8.45388 7.59623 7.82337 7.09492L5.22419 5.01225C4.95482 4.7964 4.56148 4.8398 4.34564 5.10917C4.1298 5.37854 4.17319 5.77188 4.44256 5.98772L7.04255 8.07106L7.0441 8.07228C8.13026 8.93699 9.86149 8.93699 10.9476 8.07228L13.5568 5.98833Z" />
                <path
                    d="M4.83335 0.291626C3.48755 0.291626 2.27056 0.627988 1.38724 1.44821C0.495533 2.27623 0.041687 3.50583 0.041687 5.08329V10.9166C0.041687 12.4941 0.495533 13.7237 1.38724 14.5517C2.27056 15.3719 3.48755 15.7083 4.83335 15.7083H13.1667C14.5125 15.7083 15.7295 15.3719 16.6128 14.5517C17.5045 13.7237 17.9584 12.4941 17.9584 10.9166V5.08329C17.9584 3.50583 17.5045 2.27623 16.6128 1.44821C15.7295 0.627988 14.5125 0.291626 13.1667 0.291626H4.83335ZM1.29169 5.08329C1.29169 3.74408 1.67117 2.89036 2.2378 2.36421C2.81282 1.83026 3.67916 1.54163 4.83335 1.54163H13.1667C14.3209 1.54163 15.1872 1.83026 15.7622 2.36421C16.3289 2.89036 16.7084 3.74408 16.7084 5.08329V10.9166C16.7084 12.2558 16.3289 13.1096 15.7622 13.6357C15.1872 14.1697 14.3209 14.4583 13.1667 14.4583H4.83335C3.67916 14.4583 2.81282 14.1697 2.2378 13.6357C1.67117 13.1096 1.29169 12.2558 1.29169 10.9166V5.08329Z" />
            </g>
        </g>
    </svg>
</template>