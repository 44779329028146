<template>
    <div class="bg-white/5 rounded-[10px] flex-col justify-center items-center inline-flex mt-8 mb-8 absolute"
        style="height: calc(100% - 64px);width: 60%;">
        <div class="w-5 h-5 relative flex-col justify-start items-start flex" v-if="status === 'loading'">
            <loading-outlined style="color: white; font-size: 20px;" />
        </div>
        <div class="h-[78px] flex-col justify-start items-center gap-4 inline-flex" v-if="status === 'error'">
            <div class="text-white/40 text-sm font-normal font-['Plus Jakarta Sans'] leading-snug">Sorry, generation
                failed temporarily. Please try again.</div>
            <div class="justify-start items-center gap-2 inline-flex">
                <!-- <div
                    class="px-2.5 py-[9px] bg-white/5 rounded-lg border border-white/10 justify-center items-center gap-1 flex">
                    <div class="w-4 h-4 relative"></div>
                    <div class="text-white/60 text-sm font-semibold font-['Plus Jakarta Sans'] leading-snug">Back to
                        Previous </div>
                </div>
                <div
                    class="px-2.5 py-[9px] bg-white/5 rounded-lg border border-white/10 justify-center items-center gap-1 flex">
                    <div class="w-4 h-4 relative"></div>
                    <div class="text-white/60 text-sm font-semibold font-['Plus Jakarta Sans'] leading-snug">Retry Run
                    </div>
                </div> -->
                <buttonComp type='primary' @click="undo" v-if="protocol && protocol.length">
                    <Icon>
                        <template #component>
                            <mogicUndo />
                        </template>
                    </Icon>
                    Back to Previous
                </buttonComp>
                <buttonComp type='primary' @click="refresh">
                    <Icon>
                        <template #component>
                            <mogicRefresh />
                        </template>
                    </Icon>
                    Retry Run
                </buttonComp>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    LoadingOutlined,
} from '@ant-design/icons-vue';
import { protocol, status, imageSplit } from '@/hook/protocolHook';
import buttonComp from '../UI/buttonComp.vue';
import mogicUndo from '../icons/mogicUndo.vue';
import mogicRefresh from '../icons/mogicRefresh.vue';
import Icon from '@ant-design/icons-vue';


function undo() {
    status.value = 'ready';
}
function refresh() {
    imageSplit();
}
</script>