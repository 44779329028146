<script setup>
const props = defineProps({
    title: String,
    imgUrl: String,
})
const imgName = computed(() => {
    try {
        return decodeURI(props.imgUrl.split('/').pop().split('-').slice(1).join('-'))
    } catch (e) {
        console.log(e)
        return 'something wrong with the image'
    }
})
</script>
<template>
    <div
        class="w-full h-[106px] p-3.5 bg-white/5 rounded-[10px] flex-col justify-start items-start gap-2 inline-flex mb-1">
        <div class="text-white/95 text-sm font-normal font-['Plus Jakarta Sans'] leading-snug truncate w-full"
            :title="title">{{ title }}
        </div>
        <div class="justify-start items-center gap-2 inline-flex">
            <div class="w-12 h-12 rounded-md justify-center items-center gap-2.5 flex rounded-[6px]"
                style="background-color: rgba(255, 255, 255, 0.05);">
                <img class="max-h-full relative rounded-[6px]" :src="imgUrl" crossorigin="anonymous" />
            </div>
            <div class="text-white/40 text-xs font-light font-['Plus Jakarta Sans'] leading-[18px] truncate w-[260px]">
                {{ imgName }}</div>
        </div>
    </div>
</template>