<template>
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 2378">
            <path id="Rectangle 6867" d="M11.4565 9.13043H20.1304L19.2173 12.7826H11.4565V9.13043Z" fill="#4384F4" />
            <g id="Subtract">
                <path
                    d="M11 3.65217C12.7028 3.65217 14.2605 4.27372 15.4585 5.30232L18.1061 2.76988C16.2362 1.05009 13.7407 0 11 0C7.20851 0 3.88638 2.00959 2.04064 5.02174L4.942 7.30435C6.09015 5.13231 8.37222 3.65217 11 3.65217Z"
                    fill="#EF7268" />
                <path
                    d="M4.942 13.6957C4.43771 12.7417 4.15217 11.6542 4.15217 10.5C4.15217 9.34583 4.43771 8.25835 4.942 7.30435L2.04064 5.02174C1.06337 6.61659 0.5 8.49251 0.5 10.5C0.5 12.5075 1.06337 14.3834 2.04064 15.9783L4.942 13.6957Z"
                    fill="#FBBC04" />
                <path
                    d="M15.6557 15.5217C14.4339 16.655 12.7979 17.3478 11 17.3478C8.37222 17.3478 6.09015 15.8677 4.942 13.6957L2.04064 15.9783C3.88638 18.9904 7.20851 21 11 21C13.7407 21 16.2362 19.9499 18.1061 18.2301L15.6557 15.5217Z"
                    fill="#35A853" />
                <path
                    d="M17.7108 9.13043C17.8007 9.57296 17.8478 10.031 17.8478 10.5C17.8478 12.4841 17.004 14.2711 15.6557 15.5217L18.1061 18.2301C20.1925 16.3111 21.5 13.5582 21.5 10.5C21.5 10.0358 21.4699 9.57869 21.4115 9.13043H17.7108Z"
                    fill="#4384F4" />
            </g>
        </g>
    </svg>
</template>