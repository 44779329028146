<script setup>
// import iconSend from '@/components/icons/iconSend.vue';
import Icon from '@ant-design/icons-vue';
import { RightOutlined } from '@ant-design/icons-vue';
import { useConversationStore, useChatStore } from '@/stores/conversation.js'
import buttonComp from '@/components/UI/buttonComp.vue';
import mogicClose from '@/components/icons/mogicClose.vue';
import toptip from './tailwind/toptip.vue';
import workflow from './tailwind/workflow.vue';
import { imageSplit as imageSplitFetch, status } from '@/hook/protocolHook'

const prompt = ref('')
const conversationStore = useConversationStore()
const chatStore = useChatStore()

function sendMessage() {
    if (prompt.value === '') {
        return
    }
    if (chatStore.status !== 0) {
        return
    }
    chatStore.sendMessage({
        userInput: prompt.value,
        chatId: conversationStore.conversation.chatsId
    })
    prompt.value = ''
    nextTick(() => {
        scrollToBottom()
    })
}
function imageSplit(id, uuid) {
    // const temp = chatStore.list[0]
    // imageSplitFetch(chatStore.imageUrl, conversationStore.conversation.chatsId,
    //     temp.id, temp.objectPositions)
    imageSplitFetch({
        imageUrl: chatStore.imageUrl,
        chatId: conversationStore.conversation.chatsId,
        chatMsgId: id,
        aiMsgUuid: uuid
    })
}
const scrollArea = ref(null)
function scrollToBottom() {
    if (scrollArea.value) {
        scrollArea.value.scrollTop = scrollArea.value.scrollHeight
    }
}
</script>
<template>
    <div class="conversation-container">
        <div class="scrollable-content" ref="scrollArea">
            <!-- Your scrollable content goes here -->
            <template v-for="(talk, i) in chatStore.list" :key="i">
                <toptip v-if="talk.userInput && talk.imageUrl" :title="conversationStore.conversation.title"
                    :imgUrl="chatStore.imageUrl"></toptip>
                <p class="user-input" v-else-if="talk.userInput">{{ talk.userInput }}</p>
                <workflow class="workflow" v-if="talk.aiOutput && talk.workflow"
                    :disabled="chatStore.status === 1 || status === 'loading'"
                    @run="imageSplit(talk.id, talk.aiMsgUuid)">{{
                        talk.aiOutput }}</workflow>
                <p class="ai-output" v-else-if="talk.aiOutput">{{ talk.aiOutput }}</p>
            </template>
            <p class="ellipsis-animation" v-if="chatStore.status === 1"></p>
            <div class="flex justify-center ">
                <buttonComp size="large" type='primary' class="mt-1" v-if="chatStore.status === 1">
                    <Icon>
                        <template #component>
                            <mogicClose />
                        </template>
                    </Icon>
                    Stop Generating
                </buttonComp>
            </div>
            <!-- <buttonComp @click="imageSplit">临时按钮，发起切图</buttonComp> -->

        </div>
        <div class="fixed-input">
            <!-- <input type="text" placeholder="How can I help you?" />
              -->
            <a-input v-model:value="prompt" placeholder="How can I help you?" v-show="chatStore.status === 0"
                @pressEnter="sendMessage">

                <template #suffix>
                    <buttonComp rainbow isIcon @click="sendMessage">
                        <template #icon>
                            <RightOutlined />
                        </template>
                    </buttonComp>
                </template>
            </a-input>
        </div>
    </div>
</template>

<style scoped>
.conversation-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 20px);
    border-left: 1px solid rgba(255, 255, 255, 0.08);
    background: #19191A;
    box-shadow: 0px 0px 12.1px 0px rgba(53, 62, 92, 0.07);
    padding: 24px;
}

.scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 37px;
}

.fixed-input .ant-input-affix-wrapper {
    height: 58px;
    border-radius: 10px;
    border: 1px solid var(--opacity-color-white-05, rgba(255, 255, 255, 0.05));
    background: rgba(255, 255, 255, 0.07);
    /* backdrop-blur/3xl */
    backdrop-filter: blur(calc(var(--blur-3xl, 64px) / 2));
    box-shadow: none;
}

::v-deep .fixed-input .ant-input {
    background: transparent;

}

.ai-output {
    color: var(--textColor-mian, rgba(255, 255, 255, 0.95));

    /* text-sm/normal */
    font-family: var(--font-family-sans, "Plus Jakarta Sans");
    font-size: var(--font-size-sm, 14px);
    font-style: normal;
    font-weight: var(--font-weight-normal, 400);
    line-height: var(--font-line-height-5, 22px);
    /* 157.143% */
    letter-spacing: var(--font-letter-spacing-normal, 0px);
    margin-top: 12px;
    margin-bottom: 12px;
    white-space: pre-line;
}

.user-input {
    border-radius: var(--radius-xl, 10px);
    background: var(--opacity-color-white-05, rgba(255, 255, 255, 0.05));
    padding: 14px;
    color: var(--textColor-mian, rgba(255, 255, 255, 0.95));

    /* text-sm/normal */
    font-family: var(--font-family-sans, "Plus Jakarta Sans");
    font-size: var(--font-size-sm, 14px);
    font-style: normal;
    font-weight: var(--font-weight-normal, 400);
    line-height: var(--font-line-height-5, 22px);
    /* 157.143% */
    letter-spacing: var(--font-letter-spacing-normal, 0px);
    margin-top: 28px;
    margin-bottom: 4px;
}
</style>