<template>
  <div class="welcome-top">
    <div
      class="h-[34px] w-[1070px] justify-between items-center gap-[6.61px] inline-flex"
    >
      <div class="w-[165px] h-[34px] justify-center items-center flex">
        <img :src="logoText" style="height: 34px; width: 165px" />
      </div>
      <div class="justify-start gap-[5.67px] flex">
        <selectButtonComp
          type="text"
          :options="contactOptions"
          @select="contactSelected"
          >Contact
          <Icon>
            <template #component>
              <mogicArrowDown></mogicArrowDown>
            </template>
          </Icon>
        </selectButtonComp>
        <selectButtonComp
          type="text"
          :options="policiesOptions"
          @select="policiesSelected"
          >Policies
          <Icon>
            <template #component>
              <mogicArrowDown></mogicArrowDown>
            </template>
          </Icon>
        </selectButtonComp>
        <template v-if="!email">
          <buttonComp
            type="primary"
            style="width: 82px"
            @click="openlogin = true"
            >Log In</buttonComp
          >
          <buttonComp style="width: 80px" @click="openlogin = true"
            >Sign up</buttonComp
          >
        </template>
        <template v-else>
          <buttonComp type="primary" style="width: 82px" @click="logout"
            >Log Out</buttonComp
          >
        </template>
      </div>
    </div>
    <googlelogin v-model:open="openlogin"></googlelogin>
  </div>
</template>
<script setup>
import logoText from '@/assets/png/logo-text.png'
import buttonComp from '@/components/UI/buttonComp.vue'
import selectButtonComp from '@/components/UI/selectButtonComp.vue'
import Icon from '@ant-design/icons-vue'
import mogicArrowDown from '@/components/icons/mogicArrowDown.vue'
import mogicDiscord from '@/components/icons/mogicDiscord.vue'
import mogicX from '@/components/icons/mogicX.vue'
import mogicMail from '@/components/icons/mogicMail.vue'
import googlelogin from '@/components/googlelogin/index.vue'

const contactOptions = ref([
  {
    value: 0,
    label: 'Discord',
    icon: markRaw(mogicDiscord),
    link: 'https://discord.com/invite/ktwdb3B3',
  },
  {
    value: 1,
    label: 'X',
    icon: markRaw(mogicX),
    link: 'https://x.com/intent/follow?screen_name=grados_ai',
  },
  {
    value: 3,
    label: 'Email',
    icon: markRaw(mogicMail),
    email: 'grados.ailab@gmail.com',
  },
])

const policiesOptions = ref([
  {
    value: 0,
    label: 'Terms of Use',
    link: 'https://grados-ai.notion.site/Terms-of-Service-144ef135892f8032a2caed116b4cd58f',
  },
  {
    value: 1,
    label: 'Privacy Policy',
    link: 'https://grados-ai.notion.site/Privacy-Policy-144ef135892f806a9ecbeaefcd168e28',
  },
])

function policiesSelected(value, option) {
  window.open(option.link, '_blank')
}
function contactSelected(value, option) {
  if (option.link) {
    window.open(option.link, '_blank')
  } else if (option.email) {
    const mailtoLink = `mailto:${option.email}`
    window.open(mailtoLink, '_blank')
  }
}
function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('email')
  document.cookie.split(';').forEach(cookie => {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  })

  location.reload()
}
const openlogin = ref(false)
const email = ref(localStorage.email)
onMounted(() => {
  debugger
  email.value = localStorage.email
})
</script>
<style scoped>
.welcome-top {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  margin-top: 16px;
}
</style>
