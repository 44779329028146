<template>
    <component :is="renderTooltip"></component>
</template>

<script>
import { h } from 'vue';
import { Tooltip } from 'ant-design-vue';
export default {
    name: 'TooltipComp',
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { attrs, slots, emit, expose }) {
        const renderTooltip = () => {
            if (!props.disabled) {
                return slots.default ? slots.default() : null;
            } else {
                return h(Tooltip, {
                    ...props,
                    ...attrs,
                    // open: true,
                    overlayClassName: 'mogic-tooltip',
                }, {
                    default: () => slots.default ? slots.default() : null
                });
            }
        };

        return {
            renderTooltip
        };
    }
};
</script>
<style>
.mogic-tooltip.ant-tooltip-placement-bottom:not(.ant-tooltip-arrow-hidden) {
    padding-top: 4px;

}

.mogic-tooltip .ant-tooltip-arrow::before {
    background: rgba(0, 0, 0, 0.85);
}

.mogic-tooltip .ant-tooltip-inner {
    background: rgba(0, 0, 0, 0.85);
    /* border-radius: 10px; */
    padding: 4px 8px;
    color: rgba(255, 255, 255, 0.95);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
</style>