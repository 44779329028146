<template>
    <Button
        :class="{ 'grados-button': true, 'rainbow-button': props.rainbow, 'flex-icon-button': false, 'icon-button': isIcon }"
        v-bind="$props" ref="buttonref">
        <slot name="icon"></slot>
        <slot></slot>
    </Button>
</template>

<script setup>
import { Button } from 'ant-design-vue';
const buttonref = ref(null);
const props = defineProps({ ...Button.props, rainbow: { default: undefined, type: Boolean }, isIcon: { default: undefined, type: Boolean } });
onMounted(() => {
    if (props.rainbow) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = '<div class="button__glow" style="left: 4px;"><div class="button__glow__panel button__glow__panel--1" style="translate: none; rotate: none; scale: none; transform: translate(0px, 0px); opacity: 0.8; filter: blur(15px);"></div></div>';
        // const generatedHTML = tempDiv.firstChild;
        buttonref.value.$el.appendChild(tempDiv.firstChild);
        tempDiv.replaceChildren()
        tempDiv.innerHTML = '<div class="button__glow" style="right: 4px;"><div class="button__glow__panel button__glow__panel--2" style="translate: none; rotate: none; scale: none; transform: translate(0px, 0px); opacity: 0.8; filter: blur(15px);"></div></div>'
        buttonref.value.$el.appendChild(tempDiv.firstChild);
    }
})
</script>

<style>
/* 黑色是主题色，白色是default */
.grados-button.ant-btn-lg {
    /* padding: 9px 10px; */
    height: 42px;
    line-height: 22px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    padding: 9px 10px;
    /* color: rgba(255, 255, 255, 0.60); */

    border-radius: 8px;
    /* border: 1px solid rgba(255, 255, 255, 0.12); */
    /* background: rgba(255, 255, 255, 0.05); */

}

.grados-button {
    height: auto;
    /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02); */
    border-radius: 8px;
    padding: 5px 10px;
    color: var(--textColor-reverseMain, rgba(0, 0, 0, 0.95));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.grados-button.ant-btn-default {
    background-color: #ffffff;
    border: 0px solid #ffffff;
    color: rgba(0, 0, 0, 0.95);

}

.grados-button.ant-btn-primary {
    /* border-radius: var(--radius-lg, 8px); */
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.60);
    box-shadow: none;
}

/* .grados-button .anticon {
    vertical-align: middle;
} */
/* 处理画图wasm的样式污染 */
.grados-button .anticon {
    display: inline-block;
    vertical-align: -0.125em;
}

.grados-button .anticon svg {
    vertical-align: unset;
}

.ant-btn.grados-button>.anticon+span {
    margin-inline-start: 4px;
}

.grados-button.ant-btn-primary:not(:disabled):hover {
    border-color: rgba(255, 255, 255, 0.32);
    background: rgba(255, 255, 255, 0.07);
    color: rgba(255, 255, 255, 0.95);
}

.grados-button.ant-btn-default:disabled {
    cursor: not-allowed;
    /* border-color: #424242; */
    color: rgba(0, 0, 0, 0.25);
    background-color: #737373;
    box-shadow: none;
}

.grados-button.ant-btn-default:not(:disabled):hover {
    background: #D4D4D4;
    color: rgba(0, 0, 0, 0.95);
}

.grados-button.ant-btn-primary:not(:disabled):active {
    border-color: rgba(255, 255, 255, 0.32);
    background: rgba(255, 255, 255, 0.07);
    color: rgba(255, 255, 255, 0.95);
}

.button__glow {
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    width: 65.93%;
    height: 86.36%;
}

.button__glow__panel {
    width: 100%;
    height: 100%;
    opacity: .8;
    border-radius: 14px;
    filter: blur(15px);
    will-change: transform;
}

.button__glow__panel--1 {
    background: rgb(213, 159, 255);
}

.button__glow__panel--2 {
    background: rgb(117, 215, 245);
}

.grados-button.ant-btn-default.rainbow-button {
    padding: 9px 12px;
    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.95);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: 0px;
    border-radius: 10px;
}

.grados-button.ant-btn-default.rainbow-button:hover {
    background: #FFF;
    color: rgba(0, 0, 0, 0.95);
}

.grados-button.ant-btn-default.rainbow-button.icon-button {
    padding: 10px;
    line-height: 22px;
    width: 42px;
    height: 42px;
}

.grados-button.ant-btn-text {
    padding: 4px 6px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.80);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.grados-button.ant-btn-text .anticon {
    margin-inline-start: 4px;
}

.grados-button.ant-btn-text:not(:disabled):hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.95);

}
</style>