<script setup>
import Icon from '@ant-design/icons-vue';
const props = defineProps({
    size: {
        type: String,
        default: 'L',
    },
});
// const map = {
//     L: '26px',
//     M: '22px',
//     S: '20px',
//     XL: '32px',
// }
const iconMap = {
    L: 20,
    M: 16,
    S: 14,
    XL: 24,
}
const iconStyle = computed(() => {
    return {
        padding: props.size === 'XL' ? '4px' : '3px',
        // width: map[props.size],
        // height: map[props.size],
        fontSize: iconMap[props.size] + 'px',
        lineHeight: iconMap[props.size] + 'px',
    };
});
const iconWidth = computed(() => {
    // console.log(iconMap[props.size]);
    return iconMap[props.size];
});
</script>
<template>
    <div class="icon-button-comp" :style="iconStyle">
        <Icon :width="iconWidth" :height="iconWidth">
            <template #component>
                <slot></slot>
            </template>
        </Icon>
    </div>
</template>
<style scoped>
.icon-button-comp {
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    vertical-align: middle;
}

.icon-button-comp:hover {
    display: inline-block;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
}

.icon-button-comp span {
    /* vertical-align: middle; */
}
</style>