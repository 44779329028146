<template>
    <a-modal v-model:open="open" :maskStyle="{ background: 'rgba(0, 0, 0, 0.85)' }" :footer="null" centered
        wrapClassName="mogic-simple-modal" :width="400">
        <div class="flex w-full justify-center mb-[40px]">
            <img src="@/assets/png/logo-text.png" style="height: 48px;width: 232px;display: block;" />
        </div>
        <div v-if="props.force"
            class="text-center text-white/95 text-xl font-semibold font-['Funnel Display'] leading-[30px] mb-[12px]">
            Continue
            after log in</div>
        <button class="google-white-button" @click="tryLogin" :disabled="disabled">
            <mogic-google style="position: absolute; left: 12px;"></mogic-google>
            Continue with Google</button>
        <div><span class="text-white/40 text-xs font-semibold font-['Funnel Display'] leading-[18px]">By clicking
                “Continue with Google” you agree to our </span><span
                class="text-white/95 text-xs font-semibold font-['Funnel Display'] leading-[18px]"><a
                    href="https://grados-ai.notion.site/Terms-of-Service-144ef135892f8032a2caed116b4cd58f"
                    target="_blank">Terms of Use</a>
            </span><span class="text-white/40 text-xs font-semibold font-['Funnel Display'] leading-[18px]"> and
                acknowledge that you have read and understand our  </span><span
                class="text-white/95 text-xs font-semibold font-['Funnel Display'] leading-[18px]">
                <a href="https://grados-ai.notion.site/Privacy-Policy-144ef135892f806a9ecbeaefcd168e28"
                    target="_blank">Privacy Policy.</a>
            </span></div>
    </a-modal>
</template>
<script setup>
import mogicGoogle from '../icons/mogicGoogle.vue';
const props = defineProps({
    force: Boolean
})
const open = defineModel("open", { type: Boolean, default: false })
// const open = ref(true);
const googleUrl = '/auth/google_oauth2'
const disabled = ref(false)
function tryLogin() {
    disabled.value = true;
    window.location.href = googleUrl;
}
</script>
<style>
.mogic-simple-modal .ant-modal-content {
    padding: 40px;
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: #202022;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.mogic-simple-modal .google-white-button {
    /* margin-top: 40px; */
    margin-bottom: 24px;
    height: 44px;
    width: 100%;
    padding: 10px 0px;
    border-radius: 8px;
    border: 1px solid #E4E8F1;
    background: #FFF;
    color: rgba(0, 0, 0, 0.95);
    /* font-family: "Funnel Display"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: 0px;
    position: relative;
}
</style>