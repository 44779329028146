<script setup>
import {
    PlusOutlined,
} from '@ant-design/icons-vue'
// import accountWrapper from '@/assets/png/accountWrapper.png'
import logoText from '@/assets/png/logo-text.png'
import { useUserStore } from '@/stores/user.js'
// import iconSidebarLeft from '../icons/iconSidebarLeft.vue';
import buttonComp from '../UI/buttonComp.vue';
import { useRouter } from 'vue-router'

import { useConversationStore, useChatStore } from '@/stores/conversation.js'
import email from './email.vue';
const router = useRouter()

const { user } = useUserStore()
const headerStyle = {
    height: '130px',
    padding: 0,
    lineHeight: '34px',
    color: 'rgba(255, 255, 255, 0.95)',
    fontWeight: 400,
    fontSize: '24px'
}

const { conversationList, conversation } = useConversationStore()
const chatStore = useChatStore()

function openNewTask() {
    window.open('/', '_blank')
}
function changeConversation(id) {
    // console.log('changeConversation', id)
    if (conversation.chatsId === id) {
        return
    }
    chatStore.$reset()
    router.push({ name: 'chat', params: { id: id } })
}

const showEmail = ref(!!localStorage.getItem('email'))

</script>
<template>
    <a-layout style="height: calc(-20px + 100vh);padding: 18px 20px;">
        <a-layout-header :style="headerStyle">
            <!-- <a-flex justify="space-between" align="center">
                <a-space>
                    <a-avatar :src="accountWrapper" size="32" />
                    <div style="color: rgba(255, 255, 255, 0.95);font-weight: 400;font-size: 24px">{{ user.name
                        }}</div>
                </a-space>
            </a-flex> -->
            <img :src="logoText" style="height: 34px; width: 165px;" @click.stop="$router.push('/')"
                class="cursor-pointer" />
            <buttonComp size="large" :block="true" @click="openNewTask" style="margin-top: 32px; margin-bottom: 24px;">
                <template #icon>
                    <PlusOutlined />
                </template>
                New Task
            </buttonComp>
        </a-layout-header>
        <a-layout-content class="overflow-y-auto">
            <div class="left-comp-recent">RECENT</div>
            <ul>
                <li class="conversation-list-item"
                    :class="{ 'conversation-list-item-selected': conversation.chatsId === item.id }"
                    v-for="(item, i) in conversationList" :key="i" @click="changeConversation(item.id)">
                    {{ item.title }}
                </li>
            </ul>
        </a-layout-content>
        <a-layout-footer style="height: 54px;background: transparent;padding: 0px;" v-if="showEmail">
            <email></email>
        </a-layout-footer>
    </a-layout>
</template>


<style scoped>
.left-comp-recent {
    /* margin: 0px 10px 8px 10px; */
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: var(--textColor-weakerSecondary, rgba(255, 255, 255, 0.40));
    /* text-xs/semibold */
    font-style: normal;
}

.conversation-list-item {
    height: 40px;
    padding: 7px;
    padding-left: 12px;
    /* margin: 8px; */
    /* margin-top: 0px; */
    margin-bottom: 4px;
    border-radius: 8px;
    /* background: rgba(0, 0, 0, 0.30); */
    /* box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.10); */
    color: rgba(255, 255, 255, 0.40);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.conversation-list-item:hover {
    background: rgba(0, 0, 0, 0.30);
    color: rgba(255, 255, 255, 0.95);
}

.conversation-list-item-selected {
    background: rgba(0, 0, 0, 0.30);
    color: rgba(255, 255, 255, 0.95);
}
</style>