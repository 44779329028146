<script setup>
import { useConversationStore, useChatStore } from '@/stores/conversation.js'
// import { useUserStore } from '@/stores/user.js'
import { theme } from 'ant-design-vue'
// import { storeToRefs } from 'pinia'
// import StartPage from '@/components/mainpage/StartPage.vue'
import leftComp from '@/components/mainpage/leftComp.vue';
import editorComp from '@/components/editorV2/index.vue'
// import editorComp from '@/components/editor/index.vue'
import conversationComp from '@/components/conversation/index.vue'
import { spinContainer } from '@/hook/spinHook';
import { getProtocol } from '@/hook/protocolHook';

import tlbackground from './talkmain/tlbackground.vue';
const store = useConversationStore()
const chatStore = useChatStore()
// const { conversation } = storeToRefs(store)
// const { getConversationById } = store
const { conversation, getConversationById } = store
const collapsed = ref(false)

const props = defineProps({
  id: String
})

watch(() => props.id, (newVal) => {
  // 需要有更合理的设计来处理前进后退的问题
  if (newVal) {
    spinContainer['app'].spinning = true
    getConversationById(newVal).then(() => {
      spinContainer['app'].spinning = false
    })
    if (chatStore.list.length === 0) {
      chatStore.getAllMessage(newVal, getProtocol)
    }
  } else {
    store.$reset()
    chatStore.$reset()
  }
}, { immediate: true })

const fontFamily = "'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
</script>

<template>
  <a-config-provider :theme="{
    algorithm: theme.darkAlgorithm,
    token: {
      fontFamily: fontFamily,
      colorBgLayout: '#23283D'

    },
  }">
    <div class="fixed left-0 top-0 w-screen h-screen bg-[#202022]">
      <!-- <tlbackground :width="'79vw'" :height="'25vh'"></tlbackground> -->
      <tlbackground></tlbackground>
    </div>
    <a-layout style="min-height: 100vh; height: 100vh; padding: 10px 10px 10px 0;background-color: transparent;"
      class="home-view-layout">
      <a-layout-sider v-model:collapsed="collapsed" collapsible :trigger="null" width="290"
        v-show="props.id || conversation.chatsId" style="background-color: transparent;">
        <leftComp></leftComp>
      </a-layout-sider>
      <a-layout v-if="props.id || conversation.chatsId" class="layer-container-main">
        <a-layout-content>
          <editorComp></editorComp>
        </a-layout-content>
        <a-layout-sider :width="423">
          <conversationComp></conversationComp>
        </a-layout-sider>
      </a-layout>
      <!-- <a-layout v-else class="layer-container-main">
        <a-layout-content>
          <StartPage></StartPage>
        </a-layout-content>
      </a-layout> -->
    </a-layout>

  </a-config-provider>
</template>
<style>
.layer-container-main {
  border-radius: 18px;
  /* background: var(--color-neutral-890, #19191A); */
  /* backdrop-blur/2xl */
  backdrop-filter: blur(calc(var(--blur-2xl, 40px) / 2));

  /* Neutral/BS Small */
  box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.10);
  overflow: hidden;
}

.home-view-layout .ant-layout {
  background: transparent;
}
</style>
