<template>
    <buttonComp v-bind="$props" @click="click" :class="{ 'mogic-select-open': selectCompShow }">
        <slot name="icon"></slot>
        <slot></slot>
        <selectComp v-model:value="selectCompShow" :options="props.options" @select="select" />
    </buttonComp>
</template>
<script setup>
import buttonComp from './buttonComp.vue';
import selectComp from './selectComp.vue';
const props = defineProps({
    options: Array,
    ...buttonComp.props,
});
const emits = defineEmits(['select']);
const selectCompShow = ref(false)

function select(value, option) {
    selectCompShow.value = false
    emits('select', value, option)
}

function click(event) {
    selectCompShow.value = !selectCompShow.value
}
</script>
<style>
.grados-button.ant-btn-text.mogic-select-open {
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.95);
}
</style>