<template>
    <a-tooltip :arrow="false" overlayClassName="user-menu-tooltip" placement="topLeft">
        <template #title>
            <div class="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex w-full">
                <div v-for="(option, i) in options" :key="i" @click="onSelect(option.value, option)"
                    class="self-stretch pl-2 pr-[19px] py-[5px] hover:bg-white/10 rounded-lg justify-start items-center gap-1.5 inline-flex">
                    <div class="grow shrink basis-0 h-[22px] justify-start items-center gap-1 flex">
                        <div
                            class="text-white/95 text-sm font-normal font-['Plus Jakarta Sans'] leading-snug inline-flex">
                            <Icon :style="{ fontSize: '20px', verticalAlign: 'baseline', marginRight: '4px' }">
                                <template #component>
                                    <component :is="option.icon" />
                                </template>
                            </Icon>
                            {{ option.label }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div
            class="h-[54px] p-3 rounded-[10px] border border-white/5 justify-start items-center gap-2 inline-flex w-full user-menu">
            <div class="w-[30px] h-[30px] bg-black rounded-full justify-start items-center gap-2.5 flex">
                <img class="grow shrink basis-0 self-stretch rounded-full" :src="accountWrapper" />
            </div>
            <div class="self-stretch flex-col justify-center items-start inline-flex">
                <div
                    class="self-stretch text-white/60 text-xs font-normal font-['Funnel Display'] leading-[18px] w-[180px] truncate">
                    {{ email }}</div>
            </div>
        </div>
    </a-tooltip>
</template>
<script setup>
import accountWrapper from '@/assets/png/accountWrapper.png';
import mogicLogout from '@/components/icons/mogicLogout.vue';
import Icon from '@ant-design/icons-vue';
const email = ref(localStorage.getItem('email') || '');
const options = [
    {
        value: 'logout',
        label: 'Log out',
        icon: markRaw(mogicLogout),
    },
];
function onSelect(value, option) {
    if (value === 'logout') {
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=')
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
        })
        nextTick(() => {
            window.location.href = '/';
        })
    }
}
// const selectCompShow = ref(false);

</script>
<style>
.user-menu {
    cursor: pointer;
}

.user-menu:hover {
    border-radius: var(--radius-xl, 10px);
    border: 1px solid var(--opacity-color-white-07, rgba(255, 255, 255, 0.07));
    background: var(--opacity-color-white-05, rgba(255, 255, 255, 0.05));
}

.user-menu-tooltip {
    padding: 8px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: #262626;
    width: 253px;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.user-menu-tooltip .ant-tooltip-inner {
    padding: 0px;
    background: transparent;
    box-shadow: none;
    border: none;
    min-height: none;
}
</style>