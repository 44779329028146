<script setup>
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false
    },
})
const wrapClass = computed(() => {
    return props.disabled ? 'text-white/20 bg-[rgba(255,255,255,0.03)] pointer-events-none' : 'hover:bg-[rgba(255,255,255,0.07)] bg-[rgba(255,255,255,0.05)] text-white/95'
})

</script>
<template>
    <div class="h-6 px-1.5 py-[3px]  rounded-md shadow justify-center items-center gap-1 flex" :class="wrapClass">
        <div class="text-center text-xs font-semibold font-['Plus Jakarta Sans'] leading-[18px]">
            Run
        </div>
        <div class="w-3 h-3 relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="currentColor">
                <path
                    d="M7.07626 3.20117L7.35238 3.37984C8.15012 3.89601 8.78157 4.30459 9.2177 4.67813C9.66072 5.05758 9.97212 5.4582 10.0138 5.97517C10.0241 6.10209 10.0213 6.22973 10.0057 6.35609C9.94204 6.87082 9.61387 7.25782 9.1551 7.61807C8.70347 7.97271 8.05519 8.35404 7.23621 8.83578L6.95894 8.99888C6.0824 9.5145 5.39075 9.92136 4.83024 10.1568C4.26342 10.395 3.74015 10.4982 3.24503 10.2804C3.12464 10.2274 3.01015 10.1619 2.90346 10.085C2.4647 9.76869 2.28834 9.26534 2.20621 8.65604C2.12499 8.05352 2.12499 7.25108 2.125 6.23412L2.125 5.89479C2.12499 4.83158 2.12499 3.9944 2.20913 3.36892C2.29395 2.7384 2.47609 2.21844 2.9328 1.90228C3.04314 1.8259 3.16136 1.76158 3.28543 1.71044C3.79898 1.49875 4.33447 1.6283 4.90991 1.89962C5.48076 2.16877 6.18362 2.62357 7.07626 3.20117ZM4.59006 2.57799C4.06941 2.33251 3.78437 2.31599 3.57126 2.40384C3.49682 2.43452 3.42588 2.47311 3.35968 2.51894C3.17015 2.65014 3.02917 2.89843 2.95243 3.46891C2.87582 4.03842 2.875 4.8249 2.875 5.92543V6.20373C2.875 7.25776 2.87582 8.0094 2.94948 8.55584C3.02304 9.10149 3.15807 9.34399 3.34207 9.47665C3.40609 9.5228 3.47478 9.56209 3.54702 9.59387C3.75466 9.68521 4.03214 9.67864 4.53975 9.46538C5.04809 9.25182 5.69637 8.87143 6.60487 8.33702L6.83017 8.20449C7.68065 7.70421 8.28474 7.34791 8.6919 7.02819C9.09665 6.71038 9.23428 6.48315 9.26138 6.26403C9.27076 6.1882 9.27239 6.11162 9.26625 6.03547C9.2485 5.8154 9.12066 5.58251 8.72982 5.24776C8.33664 4.911 7.74825 4.52931 6.91984 3.99327L6.69455 3.84749C5.77057 3.24963 5.10982 2.82306 4.59006 2.57799Z" />
            </svg>
        </div>
    </div>
</template>