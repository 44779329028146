<script setup lang="js">
import { ref } from 'vue';
import Icon from '@ant-design/icons-vue';
import mogicAiStar from '@/components/icons/mogicAiStar.vue';
import mogicArrowRight from '@/components/icons/mogicArrowRight.vue';
// import mogicWarning from '@/components/icons/mogicWarning.vue';
import imageComp from '@/components/UI/imageComp.vue';
import buttonComp from '@/components/UI/buttonComp.vue';
import { uploadImage } from '@/hook/awsUtils';
import message from '@/components/UI/message';
import instance from '@/hook/axiosUtils';
import { useChatStore, useConversationStore } from '@/stores/conversation.js'
import { useRouter } from 'vue-router'
// import quickStart from './tailwind/newQuickStart.vue';
const router = useRouter()
// import bg from '@/assets/svg/backgroundChat.svg';
const content = ref('');
const imgName = ref('');
// const imgName = ref('1111111111.jpg');
const imgUrl = ref('');
// const imageLoading
const chatStore = useChatStore()
const conversationStore = useConversationStore()

conversationStore.getConversationById()
// const imgUrl = ref('https://grados-ai.s3.amazonaws.com/2024-11-08-3D%E7%8B%90%E7%8B%B8%E7%9D%A1%E8%A7%89%E5%9B%BE.jpeg.m0l3xg');

function openUpload() {
    if (imgUrl.value !== '' || imgStatus.value === 'upload') {
        return;
    }
    console.log('Opening file upload dialog');
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = false;
    input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('Selected file:', file);
            if (file.size > 5242880) {
                // alert('File size exceeds the 5MB limit');
                message('warning', 'File size exceeds the 5MB limit')
                return;
            }
            imgName.value = file.name;
            // Handle the file upload here
            uploadImage('/oss/token', 'grados-ai', file.name, file).then((response) => {
                imgUrl.value = response || ''
            });
        }
    };
    input.click();
}
const sendMessageLock = ref(false)
function sendMessage() {
    if (sendMessageLock.value) return;
    sendMessageLock.value = true
    if (content.value === '' || imgUrl.value === '') {
        // message.warning('Upload an image and enter a prompt before starting the task');
        message('warning', 'Upload an image and enter a prompt before starting the task')
        sendMessageLock.value = false
        return;
    }
    localStorage.setItem('requestTemp', JSON.stringify({
        content: content.value,
        imgUrl: imgUrl.value,
        name: imgName.value
    }))
    instance.post('/chat', {
        "title": content.value.substring(0, 128),
    }).then((response) => {
        localStorage.removeItem('requestTemp')
        router.push({ name: 'chat', params: { id: response.data.id } })
        chatStore.$reset()
        chatStore.sendMessage({
            userInput: content.value,
            imageUrl: imgUrl.value,
            chatId: response.data.id
        })
    }).catch((error) => {
        sendMessageLock.value = false
        if (error.code === 41000) {
            return
        }
        message('error', 'Failed to start the task')
    });
}
const imgStatus = computed(() => {
    if (imgName.value === '' && imgUrl.value === '') return 'ready';
    if (imgName.value && imgUrl.value === '') return 'upload';
    if (imgUrl.value) return 'uploaded';
    return 'ready'
})
function deleteFun() {
    imgUrl.value = ''
    imgName.value = ''
}
let timer = null
const carouselList = ['Layering into...', 'Extract text from...', 'Remove background of...', 'Refine details through...']
const carousel = ref(carouselList[0])
let carouselIndex = 0

function jumpto() {
    router.push({ name: 'chat', params: { id: conversationStore.conversationList[0].id } })
}

const quickStartItemList = ref(['Extract all foreground products from the image ',
    'Extract all text from this picture',
    'Remove background',
    'Layering the poster as your understanding',
    'Breakdown the poster, text on one layer, background on another',
    // 'Separate all objects into different layers'
])
// const screenWidth = ref(window.innerWidth);

const scale = ref(window.outerWidth / 1920)

function handleResize() {
    scale.value = window.outerWidth / 1920
}
window.addEventListener('resize', handleResize);
onMounted(() => {
    let requestTemp = localStorage.getItem('requestTemp')
    if (requestTemp) {
        requestTemp = JSON.parse(requestTemp)
        content.value = requestTemp.content
        imgUrl.value = requestTemp.imgUrl
        imgName.value = requestTemp.name
        localStorage.removeItem('requestTemp')
    }
    timer = setInterval(() => {
        carousel.value = carouselList[carouselIndex]
        carouselIndex = (carouselIndex + 1) % carouselList.length
    }, 3000)
})
onUnmounted(() => {
    clearInterval(timer)
    window.removeEventListener('resize', handleResize);
})
</script>

<template>
    <!-- <div class="welcome-startpage" :style="{ scale: scale }"> -->
    <div class="welcome-startpage">
        <h1 class="title">The world's first LLM graphic editor</h1>
        <h2 class="title"><span>{{ carousel }}</span>in natural language</h2>
        <div class="editor flex">
            <imageComp class="flex" @click="openUpload" :src="imgUrl" :status="imgStatus" @delete="deleteFun" />
            <a-textarea v-model:value="content" :bordered="false" placeholder="Describe your layering task..."
                :maxlength="600" />
            <buttonComp rainbow @click="sendMessage" :disabled="sendMessageLock">
                <Icon>
                    <template #component>
                        <mogicAiStar />
                    </template>
                </Icon> Start Task
            </buttonComp>
        </div>
        <div class="w-[772px] text-white/95 text-lg font-bold  leading-[30px] text-left">You can try it </div>
        <div class="cards">
            <!-- <quickStart @change="(text) => { content = text }"></quickStart> -->
            <!-- <quickStartitem @click="(text) => { content = text }"></quickStartitem> -->
            <ul class="flex flex-wrap list-reset mb-0">
                <li class="mr-[10px] mb-[10px]" v-for="(item, i) in quickStartItemList" :key="i">
                    <div @click="() => { content = item }"
                        class="h-[42px] px-5 py-2.5 bg-black/70 rounded-full border border-white/5 backdrop-blur-2xl justify-start items-center gap-2.5 inline-flex hover:bg-[rgba(18,18,18,0.70)] cursor-pointer">
                        <div class="self-stretch flex-col justify-start items-start gap-0.5 inline-flex">
                            <div :title="item"
                                class="max-w-[270px] truncate text-white/60 text-sm font-normal font-['Plus Jakarta Sans'] leading-snug">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <buttonComp type="text" v-show="conversationStore.conversationList.length > 0" @click="jumpto">
            View history
            <Icon>
                <template #component>
                    <mogicArrowRight></mogicArrowRight>
                </template>
            </Icon>
        </buttonComp>
        <!-- <history v-show="conversationStore.conversationList.length > 0" @click="jumpto"></history> -->
    </div>
</template>

<style scoped>
.welcome-startpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    position: fixed;
    width: 100vw;
}

h1.title {
    margin-bottom: 16px;
    color: #EDF0F4;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    color: #FFF;
    text-align: center;
    letter-spacing: 0px;
}

h2.title {
    color: var(--inputs-select-text-text-neutral, #ACB4C0);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--textColor-secondary, rgba(255, 255, 255, 0.60));
    text-align: center;
    letter-spacing: 0px;
    margin-bottom: 0px;
}

h2.title span {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(calc(var(--blur-xl, 24px) / 2));
    display: inline-block;
    padding: var(--spacing-1, 4px) var(--spacing-2-5, 10px);
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: var(--textColor-mian, rgba(255, 255, 255, 0.95));
}

.editor {
    height: 112px;
    width: 772px;
    padding: 8px 24px 8px 8px;
    margin-top: 80px;
    margin-bottom: 32px;
    border-radius: var(--radius-2xl, 14px);
    border: var(--border-width-1, 1px) solid rgba(255, 255, 255, 0.08);
    background: var(--textColor-reverseWeakerMain, rgba(0, 0, 0, 0.70));

    /* backdrop-blur/2xl */
    backdrop-filter: blur(calc(var(--blur-2xl, 40px) / 2));

    align-items: center;
    gap: var(--spacing-2, 8px);
}

textarea {
    height: 100%;
    resize: none;
    flex: 1;
    border-radius: var(--radius-xl, 10px);
    padding: 6px 8px;
    color: rgba(255, 255, 255, 0.95);
}

textarea:hover {
    background: var(--opacity-color-white-07, rgba(255, 255, 255, 0.07));
}

textarea:focus {
    background: var(--opacity-color-white-07, rgba(255, 255, 255, 0.07));
}

textarea::placeholder {
    color: var(--textColor-weakerSecondary, rgba(255, 255, 255, 0.40));

    /* text-base/normal */
    /* font-family: var(--font-family-sans, "Plus Jakarta Sans"); */
    font-size: var(--font-size-base, 16px);
    font-style: normal;
    font-weight: var(--font-weight-normal, 400);
    line-height: var(--font-line-height-6, 26px);
    /* 162.5% */
    letter-spacing: var(--font-letter-spacing-normal, 0px);
}

.cards {
    display: flex;
    justify-content: center;
    /* width: 100%; */
    width: 772px;
    margin-top: 16px;
    margin-bottom: 32px;
}
</style>